<template>
    <div class="AssociatedItems">
      <div class="head_img">
        <div class="img_title">
          <div class="img">
            <img
              :src="chamberinfo.chamberCommerceLogo"
              alt=""
            />
          </div>
          <div class="title">
            <p class="merchant_name">{{ chamberinfo.chamberCommerceName }}</p>
           <p>{{ chamberinfo.province }}-{{ chamberinfo.city }}</p>
          </div>
        </div>
  
     
      </div>
      <div class="switch_tab">
        <div class="label_management" >
              <div class="input-with">
                  <el-input placeholder="请输入项目名称" v-model="queryInfo.projectName" class="input-with-select">
                      <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                  </el-input>
                 
              </div>
              <el-table :data="listData" stripe style="width: 100%" class="in_table"
                  :header-cell-style="{ background: '#F8F9FB', color: '#595959' }">
                  <el-table-column label="编号" align="center" type="index"></el-table-column>
                  <el-table-column label="项目名称" align="center">
                      <template v-slot="{ row }">
                          <span>{{ row.projectName }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column label="项目时间" prop="" align="center">
                    <template v-slot="{ row }" >
                      <span>{{ row.startTime }}至{{ row.endTime }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="项目目的" prop="projectPurpose" align="center"></el-table-column>
              
                  <el-table-column label="项目地点" prop="" align="center">
                    <template v-slot="{ row }" >
                      <span>{{ row.city }}-{{ row.region }}</span>
                    </template>
                  </el-table-column>
              
                  <el-table-column label="考察人数" prop="inspectNumber" align="center"></el-table-column>
              
                  <el-table-column label="考察领域" prop="" align="center">
                    <template v-slot="{ row }">
              <span v-for="(o, index) in row.areasInvestigation" :key="index">
                <span style="color: #4e93fb; padding: 0px 5px">#{{ o.areas[1] }}</span>
              </span>
            </template>
                  </el-table-column>
                  <el-table-column label="操作" align="center" class-name="small-padding fixed-width" >
                      <template slot-scope="scope">
  
  
                          <el-button style="color:#146AFF" type="text" @click="torecord(scope.row)">详情
                          </el-button>
                        
  
                      </template>
                  </el-table-column>
              </el-table>
              <div class="new_page">
                  <el-pagination :current-page="queryInfo.pageNum" :page-sizes="[10, 30, 50]"
                      :page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"
                      @size-change="handleSizeChange" @current-change="handleCurrentChange" />
              </div>
          </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import { getcommercet,getInspectDemand } from '@/api/chambercenter'
  export default {
    name: 'AssociatedItems',
    computed: {
    id() {
      return this.$route.query.id * 1
    }
  },
    data() {
      return {
        active: 2,
        //tab切换
        typeIndex: 1,
        form: {
          commodityCoverPicture: '',
          num: 1
        }, //表单收集
        queryInfo:{
          pageNum:1,
          pageSize:10,
          chamberCommerceId:this.$route.query.id * 1,
          companyId:null,
          projectName:null,
        },
      listData:[],
      total:0,
        chamberinfo:{},//商会详细
       
      }
    },
    created() {
      this.getcommercet()
      this.getInspectDemand()
    },
    methods: {
     async getInspectDemand(){
      const res=await getInspectDemand(this.queryInfo)
      if(res.data.resultCode==200){
        this.listData=res.data.data.list
        this.total=res.data.data.total
        this.listData.forEach((o)=>{
            if (o.areasInvestigation != null) {
            o.areasInvestigation = JSON.parse(o.areasInvestigation)
          }
        })
      }
      },
       //获取商会详情
   async getcommercet(){
        const res =await getcommercet(this.id)
        if(res.data.resultCode==200){
            this.chamberinfo=res.data.data
           
        }
    },
       // 分页
       handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getInspectDemand()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.getInspectDemand()
      // console.log(`当前页: ${val}`)
    }
    
   
   
    }
  }
  </script>
  <style lang="less" scoped>
  ::v-deep .el_upload .el-form-item {
    margin-bottom: 0px;
  }
  ::v-deep .name_form .el-input__suffix {
    position: absolute;
    height: 100%;
    right: -54px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    pointer-events: none;
  }
  ::v-deep .el-step__title {
    font-size: 14px;
    line-height: 38px;
  }
  
  ::v-deep .name_form .el-input__inner {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 41px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 556px;
  }
  ::v-deep .name_form .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 496px;
  }
  
  .AssociatedItems {
    .head_img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background: #ffffff;
     
      .img_title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 10px 5px 10px;
  
        img {
          width: 68px;
          height: 68px;
          border-radius: 5px;
        }
  
        .title {
          margin: 0px 0px 0px 10px;
  
          .merchant_name {
            font-weight: 550;
            font-size: 18px;
            color: #1f2d3d;
            margin: 0px;
            position: relative;
            top: -5px;
          }
  
          .merchant_type {
            position: relative;
            top: 10px;
            padding: 4px 8px;
            color: #2e7aff;
            background: #e1ecff;
            border-radius: 4px;
            font-size: 13px;
            line-height: 28px;
            text-align: center;
          }
        }
      }
    }
    .switch_tab {
      background: #ffffff;
      margin-top: 20px;
    
      .essential_information {
        padding: 30px 40px;
        .general_input {
          width: 845px;
          border: 1px solid #d4d4d4;
          padding: 14px 16px;
  
          .input_flex {
            display: flex;
            justify-content: space-between;
            padding: 7px 0px;
            span {
              padding-left: 15px;
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
        .img_tips {
          position: relative;
          top: -10px;
          color: #6ba1ff;
        }
  
        h4 {
          color: #146aff;
        }
        .dialog-footer{
      display: flex;
      justify-content: center;
  }
      }
    }
    
    .label_management {
          padding: 10px 10px;
  
          .new_page {
              margin: 20px auto;
              width: 100%;
              text-align: center;
          }
  
          .input-with {
              width: 100%;
              overflow: hidden;
              padding: 20px 0px;
  
              .input-with-select {
                  width: 400px;
                  float: left;
              }
  
              .seach {
                  background-color: #448aff;
                  color: #fff;
                  border-radius: 0;
                  border: 1px solid #448aff;
              }
          }
      }
  }
  </style>
  